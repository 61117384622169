import { defineStore } from 'pinia';
import gateService from '@/services/gate.service';
import createHumps from 'lodash-humps/lib/createHumps';
import { snakeCase } from 'lodash-es';
const snakes = createHumps(snakeCase)

type RegistrationProps = {
    email: string;
    actionRequest: string
};

export const guestProfile = defineStore({
    id: 'guestProfile',
    state: () => ({
        one: <RegistrationProps>{
            email: "",
            actionRequest: "subscribe_to_newsletter"
        },
    }),

    getters: {
        getOne: (state) => state.one,

    },
    actions: {
        // setOne() {
        //     const initialObj: RegistrationProps = {
        //         email: "",
        //     };
        //     this.one = initialObj
        // },


        async save({ appId }: { appId: string }) {
            try {
                const dataToStore = await gateService.doGuestRequest({
                    one: snakes({
                        appId: appId,
                        email: this.one.email,
                        actionRequest: this.one.actionRequest
                    }),
                });

                if (dataToStore && dataToStore.status) {
                    return ({ save: true, response: dataToStore });
                }
                return dataToStore

            } catch (err: any) {
                console.log(err)
                throw err
            }

        }


    }
});
